import firebase from 'firebase/app';
import "firebase/storage";
import "firebase/database";
import 'firebase/auth';
import moment from "moment"


   // Your web app's Firebase configuration
   var firebaseConfig = {};
  if(process.env.NODE_ENV === 'production'){
    
    console.log("production");
    firebaseConfig = {
      apiKey: "AIzaSyD5o9smlYZQpBVIBNVcrGiNaKre3wezoOE",
      authDomain: "curbsideproduction114.firebaseapp.com",
      databaseURL: "https://curbsideproduction114.firebaseio.com",
      projectId: "curbsideproduction114",
      storageBucket: "curbsideproduction114.appspot.com",
      messagingSenderId: "12567865479",
      appId: "1:12567865479:web:9df14038396c9d59207022",
      measurementId: "G-TB0D041EX8"
    };
  } else {
    console.log("Dev");
    firebaseConfig = {
        apiKey: "AIzaSyDtXa3rYx2FegAbA0CrVC4hLECmICZ62LY",
        authDomain: "curbside-1245.firebaseapp.com",
        databaseURL: "https://curbside-1245.firebaseio.com",
        projectId: "curbside-1245",
        storageBucket: "curbside-1245.appspot.com",
        messagingSenderId: "729553212897",
        appId: "1:729553212897:web:926552f4e8fe26a94aeea0",
        measurementId: "G-9NSY6QD2M3"
      };
    
  } 
  firebaseConfig = {
    apiKey: "AIzaSyDtXa3rYx2FegAbA0CrVC4hLECmICZ62LY",
    authDomain: "curbside-1245.firebaseapp.com",
    databaseURL: "https://curbside-1245.firebaseio.com",
    projectId: "curbside-1245",
    storageBucket: "curbside-1245.appspot.com",
    messagingSenderId: "729553212897",
    appId: "1:729553212897:web:926552f4e8fe26a94aeea0",
    measurementId: "G-9NSY6QD2M3"
  };
  
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export function onAuthStateChange(callback) {
    firebase.auth().onAuthStateChanged(user=>{
      if(user) {
        user.getIdTokenResult().then((idTokenResult)=>{
          callback({loggedIn: true, user: user, role: idTokenResult.claims.role})
        }).catch((error)=>{
          console.log(error);
          callback({loggedIn: true, user: user, role: "NA"})
        })
      } else {
        callback({loggedIn: false})
      }
    })
  }

  export function login(username, password) {
    return new Promise((resolve, reject)=>{
      firebase
        .auth()
        .signInWithEmailAndPassword(username, password)
        .then(() => resolve())
        .catch(error => reject(error));
    })
  }

  export function logout() {
    firebase.auth().signOut();
  }

  export function modifyParkingLot(parking, store) {
    const database = firebase.database();
    const parkingLotRef = database.ref("/parkinglot/" + store);
    parkingLotRef.set(parking);
  }

  export function modifySpecificSpot(store, parkingSpot, index, key) {
    const database = firebase.database();
    const parkingSpotRef = database.ref("/parkinglot/" + store +"/" + index + "/" + key);
    return parkingSpotRef.set(parkingSpot);
  }

  export function modifyOrders(key, order, store) {
    const database = firebase.database();
    const date = moment();
    const ordersRef = database.ref("/checks/" + date.format("MM-DD-YYYY")+"/" + store+ "/" + key);
    return ordersRef.set(order);
  }
export default firebase;