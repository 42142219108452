import React, { useState, useEffect } from "react";
import axios from "axios";

import { Grid, AppBar, Typography, TextField, Button, CircularProgress, Toolbar, IconButton } from "@material-ui/core";
import {useParams} from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    }, 
    toolbar: {
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    body: {
        padding: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
        alignSelf: 'flex-end',
    },
}));
const SpotInput = (props) => {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                        <Typography variant="h5" noWrap>
                            Texas Roadhouse - Curbside
                        </Typography>
                        <Typography  variant="h5" noWrap>
                            York, PA
                        </Typography>
                    </div>

                </Toolbar>
            </AppBar>
            <div className={classes.body}>
                <SpotReporter />
            </div>
        </div>
    )
}
const NoIdError = (props) =>{

    return(
        <React.Fragment>
            <Typography variant="h4">Error: Incorrect Link</Typography>
            <p>
                Unfortunately, the link you have provided is formatted incorrectly. Please check the link and try again. If the error persists, check in with the tent and
                we will be able to help you.
            </p>
        </React.Fragment>
    )
}
const IncorrectIdError = (props) =>{

    return(
        <React.Fragment>
            <Typography variant="h4">Error: Invalid ID</Typography>
            <p>
                Unfortunately, we are unable to find you in our system with the information provided. Please check to make sure you copied the link correctly and try again. If this did not work, please check in the tent and we will be 
                happy to assist you.
            </p>
        </React.Fragment>
    )
}
const SpotReporter = (props) => {
    let { id } = useParams();
    const [orderId, setOrderId] = useState(null);
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [noId, setNoId] = useState(false);
    const [spotText, setSpotText] = useState("");
    var functionUrl = "http://localhost:5001/curbside-1245/us-central1/";
    if(process.env.NODE_ENV === 'production'){
        functionUrl = "https://us-central1-curbsideproduction114.cloudfunctions.net/"
    } else {
        functionUrl = "https://us-central1-curbside-1245.cloudfunctions.net/";
    }
    useEffect(()=>{
        if(id){
            getOrder();
        } else {
            setLoading(false);
            setNoId(true);
        }
    }, [])
    const getOrder = async () =>{
        if(id !== "") {
            const baseUrl = functionUrl + "orderdata?orderid=" + id;
            const response = await axios.get(baseUrl);
            const data = response.data.body;
            const code = response.data.code;
            setLoading(false);
            if(code > 0) {
                setError(true);
                console.log("Error loading: " + response.data.message);
                return;
            }
            console.log(data);
            setOrderId(data.key);
            setOrder(data.data);

        } else {
            console.log("No id provided");
            setLoading(false);
            setError(true);
        }

    }
    const submitSpot = async () => {
        const temporder = order;
        temporder.spotNumber = spotText;
        temporder.arrived = true;
        setOrder(temporder);
        setLoading(true);
        const baseUrl = functionUrl +"reportlocation?orderid="+orderId + "&spot="+spotText;
        const response = await axios.get(baseUrl);
        const data = response.data;
        const code = data.code;
        console.log(data);
        console.log(code);
        if(code > 0) {
            setError(true);
        }
        setLoading(false);
    }
    const handleTextChange = (event) => {
        setSpotText(event.target.value)
    }
    if(loading) {
        return(
            <React.Fragment>
                <CircularProgress />
            </React.Fragment>
        )
    } else if (noId){
        return (<NoIdError />)
    } else if (error){
        return (
            <IncorrectIdError />
        )
    } else if(order.arrived) {
        return(
            <React.Fragment>
                <Typography variant="h3">Order #: {order.checkNumber}</Typography>
                <Typography variant="h6">See you at spot {order.spotNumber}</Typography>
            </React.Fragment>
        )
    }else {
        return(
            <React.Fragment>
                <Grid container spacing={4}>
                    <Grid item xs={12}> 
                        <Typography>
                            Texas Roadhouse
                        </Typography>
                        <br />
                        <Typography>
                            York, PA
                        </Typography>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        Thanks for ordering with us, {order.firstName}. If you'd like to use curbside, please enter the spot number you're located. If you're not interested in curbside, please check in at the tent.
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        id="outlined-textarea"
                        label="Enter Spot Number"
                        placeholder="Example: 101"
                        multiline
                        value={spotText}
                        onChange={handleTextChange}
                        variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            onClick={(e)=>{
                                submitSpot();
                            }}
                        >Submit</Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default SpotInput;