import React, { useState } from "react";
import { FormControlLabel, Checkbox, IconButton, Dialog, Snackbar, AppBar, Toolbar, TextField, Paper, Grid, Container, Typography, Divider, CircularProgress, Box, Button } from "@material-ui/core";
import {UserContext, AppContext} from "../../utils/appcontext";

import { useEffect } from "react";
import {useParams} from "react-router-dom";
import {getEmployee, createEmployee} from "../../utils/userfunctions";
import {useStyles} from "../../utils/styles";
import firebase from "../firebase";
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export const UserTable = (props) => {
    const classes = useStyles();
    const [togoEmployees, setTogoEmployees] = useState({});
    const [modifyEmployee, setModifyEmployee] = useState("");

    // Show States
    const [showEmployeeCreation, setShowEmployeeCreation] = useState(false);


    // State functions
    const handleEmployeeCreation = () => {
        setShowEmployeeCreation(!showEmployeeCreation);
    }

    useEffect(()=>{
        const employeeRef = firebase.database().ref("/users/114");
        employeeRef.once("value", (snapshot)=>{
            if(snapshot.val()){
                setTogoEmployees(snapshot.val());
            }
        });

    }, [])
    return(
        <React.Fragment>
            <UserCreationForm 
                employee = {modifyEmployee}
                employees = {togoEmployees}
                show={showEmployeeCreation}
                handleClose={handleEmployeeCreation}
            />
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={3}>
                            Name
                        </Grid>
                        <Grid item xs={3}>
                            Email
                        </Grid>
                        <Grid item xs={3}>
                            Curbside Id
                        </Grid>
                        <Grid item xs={2}>
                            Profile
                        </Grid>
                        <Grid item xs={1}>
                            </Grid>
                    </Grid>
                </Grid>
                </Grid>
                <Divider />
                <Grid container>
                {
                    Object.keys(togoEmployees).filter((key)=>{
                        let employee = togoEmployees[key];
                        if(employee['schedules']){
                            for(let scheduleKey in employee['schedules']){
                                let schedule = employee.schedules[scheduleKey];
                                if(schedule === 1089232597){
                                    return true;
                                }
                            }
                        }
                        return false;
                    }).map((key, index)=>{
                        const employee = togoEmployees[key];
                        return(
                            <Grid item xs={12} key={key}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        {employee.name}
                                    </Grid>
                                    <Grid item xs={3}>
                                        {employee.email}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button 
                                            onClick={()=>{
                                                if(!employee.uid){
                                                    setModifyEmployee(key);
                                                    handleEmployeeCreation();
                                                }
                                                

                                            }}
                                            size="small">
                                                {(employee.uid ? employee.uid : "Needs Created")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        Profile
                                    </Grid>
                                    <Grid item xs={1}>
                                        </Grid>
                                </Grid>
                            </Grid>
                        );
                    })

                }

            </Grid>
        </React.Fragment>
    )
}

const newEmployee = {
    firstName: "",
    lastName: "",
    phone: "",
    hotschedulesId: "",
    email: "",
    sendEmail: true,
    sendSms: true,
    isAdmin: false,
    manager: false,
}

const UserCreationForm = (props) => {
    const {show, handleClose, employee, employees} = props;
    const {user} = React.useContext(UserContext);
    const {store} = React.useContext(AppContext);
    const [employeeState, setEmployeeState] = useState({})
    const [showEnterError, setShowEnterError] = useState(false);

    useEffect(()=>{
        const tempEmployee = {...newEmployee};
        if(employees[employee]){
            tempEmployee.firstName = employees[employee].firstname;
            tempEmployee.lastName = employees[employee].lastname;
            tempEmployee.phone = String(employees[employee].contactNumber.areaCode) + String(employees[employee].contactNumber.prefix) + String(employees[employee].contactNumber.number);
            tempEmployee.hotschedulesId = employee;
            tempEmployee.email = employees[employee].email;
            console.log(employees[employee].firstName);
            console.log(employees[employee])
            setEmployeeState({...tempEmployee});
            console.log(employeeState);
        }
    }, [employee])
    const handleChange = (e, index) =>{

    }
    const handleCheckedChange = (e, index) => {
        const value = e.target.checked;
        const tempEmployee = employeeState;
        tempEmployee[index] = value;
        setEmployeeState({...tempEmployee});
    }
    const handleSnackClose = () => {
        setShowEnterError(!showEnterError)
    }
    const submit = async () =>{
        const tempEmployee = employeeState;
        tempEmployee.store = store;
        console.log(createEmployee(tempEmployee))
    }
    
    const classes = useStyles();
    return(
        <Dialog fullScreen open={show} onClose={handleClose}>
            <Snackbar open={showEnterError} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error">
                    Error!
                </Alert>
            </Snackbar>
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Employee Creation
                </Typography>

            </Toolbar>
        </AppBar>
            <Container style={{paddingTop: 68}} maxWidth="sm">
                <form  noValidate autoComplete="off">
                <TextField
                    id="firstName"
                    label="First Name"
                    value={employeeState.firstName}
                    onChange={(e)=>{
                        handleChange(e, "firstName")
                    }}
                    fullWidth
                />
                <br />
                <TextField
                
                    id="lastName"
                    label="Last Name"
                    value={employeeState.lastName}
                    onChange={(e)=>{
                        handleChange(e, "lastName")
                    }}
                    fullWidth
                />
                <br />
                <TextField
                    id="phone"
                    label="Phone Number"
                    value={employeeState.phone}
                    onChange={(e)=>{
                        handleChange(e, "phone")
                    }}
                    fullWidth
                />
                <br />
                <TextField
                    id="email"
                    label="Email"
                    value={employeeState.email}
                    onChange={(e)=>{
                        handleChange(e, "phone")
                    }}
                    fullWidth
                />
                <br />
                <Grid container>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={employeeState.sendSms}
                                    onChange={(e)=>{
                                        handleCheckedChange(e, "sendSms")
                                    }}
                                    color="primary"
                                    name="sendsms"
                                    />
                            }
                            label="SMS Notifications"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={employeeState.sendEmail}
                                    onChange={(e)=>{
                                        handleCheckedChange(e, "sendEmail")
                                    }}
                                    color="primary"
                                    name="sendemail"
                                    />
                            }
                            label="Email Notifications"
                        />
                    </Grid>
                    {(user.role.toLowerCase() !== "admin") ? null :
                        <React.Fragment>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={employeeState.isAdmin}
                                            onChange={(e)=>{
                                                handleCheckedChange(e, "isAdmin")
                                            }}
                                            color="primary"
                                            name="isAdmin"
                                            />
                                    }
                                    label="Administrator"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={employeeState.manager}
                                            onChange={(e)=>{
                                                handleCheckedChange(e, "manager")
                                            }}
                                            color="primary"
                                            name="manager"
                                            />
                                    }
                                    label="Manager"
                                />
                            </Grid>                            
                        </React.Fragment>                     
 }
                </Grid>
                </form>
                <Button onClick={submit} color="primary">Submit</Button>
            </Container>
        </Dialog>
    );
     
}

const UserProfile = (props) =>{
    const {user} = React.useContext(UserContext);
    let { id } = useParams();
    const [userView, setUserView] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        if(id) {
            setLoading(true);
            getEmployee(id).then((userData)=>{
                setUserView(userData);
                setLoading(false);
            })
        } else {
            setUserView(user.user)
        }
    }, [id])
    if(loading){
        return (<Box style={{marginTop: 8}}>
                <Typography variant="h4" component="h4">Loading User...</Typography>
                <br />
                <CircularProgress color="inherit" />
            </Box>)

    } else {
        return(
            <Container>
                <UserView user={userView} />
            </Container>
        )
    }

}

const UserView = (props) => {
    const {user} = props;
    const [role, setRole] = React.useState(null)
    let { id } = useParams();


    return(
        <Paper style={{marginTop: 8}}>
            <Typography variant="h4" component="h4">User Profile </Typography>
            <Divider />
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6" component="span">Display Name: </Typography> {user.displayName}
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h6" component="span">E-Mail:</Typography> {user.email}
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h6" component="span"> Role: </Typography> 
                </Grid>
            </Grid>
        </Paper>
    )
}

export default UserProfile;