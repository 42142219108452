import React, {useEffect, useState} from "react";
import axios from 'axios';
import {useParams} from "react-router-dom";


import { Container, Typography, CircularProgress } from "@material-ui/core";

export const NameView = (props) =>{
    let { nameId } = useParams();
    const [currentName, setCurrentName] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(()=>{
        if(!nameId) {
            setLoading(false);
        } else {
            axios.get('http://localhost:5001/curbside-1245/us-central1/name?name='+nameId) 
            .then((response)=>{
                const data = response.data;
                console.log(data);

                if(data.status === "error"){
                    setError(true);
                } else {
                    setCurrentName(data.name);
                }
                setLoading(false);

            })
            .catch(()=>{
                setError(true);
                setLoading(false);
            })
        }

    }, []);
    if(loading){
        return (
            <React.Fragment>
                <CircularProgress />
            </React.Fragment>
        )
    } if(!nameId) {
        return (
            <React.Fragment>
                No name provided
            </React.Fragment>
        )
    } else {
        return(
            <React.Fragment>
                <Container>
                    <Typography>
                        Name: {currentName.name}
                    </Typography>
                    <Typography>
                        Projected Arrival Time: {currentName.time}
                    </Typography>
                </Container>
            </React.Fragment>
        )
    }

}