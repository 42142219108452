import axios from "axios";
import moment from "moment";

export const Messages = {
    welcome: "Thank you for choosing Texas Roadhouse! Your order should be completed in approximately 30 minutes. Please park in a numbered spot in the rear of the building. Reply to this text with the word HERE when you are parked.",
    ready: "Thank you for your patience! Your order is now ready. Please check in with the tent to pick up your food.",
}

export const sendSMS = (phone, message) => {
    if(phone.length < 11) {
        phone = "1"+phone;
    }
    let baseUrl
    // Test base url
    //baseUrl = "http://localhost:5001/curbside-1245/us-central1";
    
    // Staging URL
    //const baseUrl = "https://us-central1-curbside-1245.cloudfunctions.net";
    
    // Production URL
    if(process.env.NODE_ENV === 'production'){
        baseUrl = "https://us-central1-curbsideproduction114.cloudfunctions.net";
    } else {
    // Staging URL
        baseUrl = "https://us-central1-curbside-1245.cloudfunctions.net";
    }
        // Staging URL
        //baseUrl = "http://localhost:5001/curbside-1245/us-central1";

    // Production Url

    axios.request(baseUrl + "/sendsms?destPhone="+phone+"&message=" + encodeURI(message + "\n-Texas Roadhouse")).then((response)=>{
        console.log(response.data);
    });
}